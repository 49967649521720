$(document).ready(function() {
	$('.datatable').dataTable({
		'iDisplayLength': 10,
		language: {
				processing:     'Buscando...',
				search:         'Buscar',
				lengthMenu:     'Mostrar _MENU_ registros',
				info:           'Mostrando _START_ a _END_ de _TOTAL_ registros',
				infoEmpty:      'No se encontraron registros',
				infoFiltered:   ' (De un total de _MAX_ registros)',
				infoPostFix:    '',
				loadingRecords: 'Cargando vista...',
				zeroRecords:    'No se encontraron registros',
				emptyTable:     'No se encontraron registros',
				paginate: {
					first:      'Primer',
					previous:   'Anterior',
					next:       'Siguiente',
					last:       '&Uacute;ltimo',
			}
		}
	});

	// SUBIR CSV
	$('body').on('click', '#btnSubirArchivo', function(e){
		var fileInput = document.getElementById('file');
		var file      = fileInput.files[0];
		var reader    = new FileReader();
		var btn       = $(this);

		reader.onload = function(e) {
			count_file = (reader.result.split('\n').length) - 1;
			btn.text('SUBIENDO...');
			btn.prop('disabled', true);
			updateProgressbar('pbar_almacena_DB', 1);
			process_id = setInterval(getProgressStatusSubirCsv, 1500);	
			$('#formulario').submit();
		}
		reader.readAsText(file);
	});

	// GEOLOCALIZAR
	$('body').on('click', '#btnGeolocalizar', function(e){
		$(this).text('GEOLOCALIZANDO...');
		$(this).prop('disabled', true);
		updateProgressbar('pbar', 1);
		process_id = setInterval(getProgressStatusGeolocalizar, 60000);	
		$('#formulario').submit();
	});
} );

$(document).on('click', '.eliminar-item', function(){
	$('#modal-app_formEliminar').attr('action', $(this).attr('link-delete'));
	$('#modal-app_eliminar').modal('show');
});

$(document).on('change', '.select-drop', function(){
	var target 		   = $(this).attr('target');
	var target_id 	   = $(this).attr('target-id');
	var target_name    = $(this).attr('target-name');
	var target_value   = $(this).val();
	var control_target = $(this).attr('control_target');

	$.ajax({
		url: getSelectDrop,
		type: 'POST',
		headers: {
			'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
		},
		data: {
			target      : target,
			target_id   : target_id,
			target_name : target_name,
			target_value: target_value
		},
		success: function(data) {
			$('#' + control_target).empty();
			$('#' + control_target).append('<option value="-1">Seleccione</option>');
			$.each(data.data, function(index, value){
				$('#' + control_target).append('<option value="' + value.id + '">' + value.name + '</option>');
			});
		},
		error: function(e) {
			swal("Error", "Favor reintentar", "error");
		}
	});
});

function getProgressStatusSubirCsv()
{
	$.ajax({
		url: urlGetProgressStatusSubirCsv,
		type: 'POST',
		headers: {
			'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
		},
		data: {
			'proceso'  : $('#proceso').val(),
			'cantidad' : count_file
		},
		success: function(data) {
			if(data.status == 'success')
			{
				updateProgressbar('pbar_almacena_DB', data.valor);
				if(data.valor >= '100')
				{
					clearInterval(process_id);
				}
			}
		}
	});
}

function getProgressStatusGeolocalizar()
{
	$.ajax({
		url: urlGetProgressStatusGeolocalizar,
		type: 'POST',
		headers: {
			'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
		},
		data: {
			'proceso' : $('#proceso').val()
		},
		success: function(data) {
			if(data.status == 'success')
			{
				updateProgressbar('pbar', data.valor);
				if(data.valor >= '100')
				{
					clearInterval(process_id);
				}
			}
		}
	});
}

function updateProgressbar(control_id, valor)
{
	$('#' + control_id).attr('aria-valuenow', valor);
	$('#' + control_id).css('width', valor + '%');	
	$('#' + control_id).text(valor + '%');
}

function getGrafico(proceso_id)
{
	$.ajax({
		url: urlGetGraficoByProceso,
		type: 'POST',
		headers: {
			'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
		},
		data: {
			'proceso' : proceso_id
		},
		success: function(data) {
			if(data.status == 'success')
			{
				var pieChartCanvas = $("#pieChart").get(0).getContext("2d");
				var pieChart = new Chart(pieChartCanvas);
				var PieData = $.parseJSON(data.json);
						
				var pieOptions = {
					segmentShowStroke: true,
					segmentStrokeColor: "#fff",
					segmentStrokeWidth: 2,
					percentageInnerCutout: 50, // This is 0 for Pie charts
					animationSteps: 100,
					animationEasing: "easeOutBounce",
					animateRotate: true,
					animateScale: false,
					responsive: true,
					maintainAspectRatio: true,
					legendTemplate: "<ul class=\"<%=name.toLowerCase()%>-legend\"><% for (var i=0; i<segments.length; i++){%><li><span style=\"background-color:<%=segments[i].fillColor%>\"></span><%if(segments[i].label){%><%=segments[i].label%><%}%></li><%}%></ul>"
				};
				pieChart.Doughnut(PieData, pieOptions);    
			}
		}
	});
}

$('#btnDescargaCsv').on('click', function(){
	var proceso_id = $('#proceso').val();
	if(proceso_id != '-1') window.location.href = 'download/' + proceso_id;
});
